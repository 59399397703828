<template>
  <v-container>
    <v-list>
      <ReportItem
        v-for="(report, index) in reports"
        v-bind:key="index"
        :report="report"
        :id="personId"
      >
      </ReportItem>
    </v-list>
  </v-container>
</template>

<script>
import { saveChildRoute } from '@/router/helper';
import ReportItem from '@/components/ReportItem';

export default {
  name: 'PersonReport',
  components: { ReportItem },
  props: {
    id: { type: String },
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      reports: [],
    };
  },
  async created() {
    this.reports = await this.apiList({
      resource: 'app/report',
      query: 'type=schoolClass',
    });
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
